@charset "utf-8";

@import url('https://fonts.googleapis.com/css?family=Oswald|Raleway');

@media screen and (max-width: 60rem) {
    .preview-wrapper {
        width: auto;
        margin: 0;
        padding: 1rem 2rem;
        flex-wrap: wrap;
    }
    
    .preview-left {
        flex: 2 1 100%;
        padding: 0;
    }
    
    .preview-right {
        flex: 2 1 100%;
        padding: 1rem 0 0 0;
        font-size: 2rem;
        text-align: center;
    }

    .button {
        font-size: 1.5rem;
        padding: .5rem;
    }

    .key-points {
        flex-direction: column;
    }

    .key-point {
        padding: 1rem 2rem;
    }
}